/** Super Simple Slider by @intllgnt **/

// ; (function ($, window, document, undefined) {

//     $.fn.sss = function (options) {
//         // Options

//         var settings = $.extend({
//             slideShow: true,
//             startOn: 0,
//             speed: 3500,
//             transition: 400,
//             arrows: true,
//         }, options);

//         console.log(this);

//         return this.each(function () {

//             // Variables

//             var
//                 wrapper = $(this),
//                 slides = wrapper.children().wrapAll('<div class="sss"/>').addClass('ssslide'),
//                 slider = wrapper.find('.sss'),
//                 slide_count = slides.length,
//                 transition = settings.transition,
//                 starting_slide = settings.startOn,
//                 target = starting_slide > slide_count - 1 ? 0 : starting_slide,
//                 animating = false,
//                 clicked,
//                 timer,
//                 key,
//                 prev,
//                 next,

//                 // Reset Slideshow

//                 reset_timer = settings.slideShow ? function () {
//                     clearTimeout(timer);
//                     timer = setTimeout(next_slide, settings.speed);
//                 } : $.noop;

//             // Animate Slider

//             function get_height(target) {
//                 return ((slides.eq(target).height() / slider.width()) * 100) + '%';
//             }

//             function animate_slide(target) {
//                 if (!animating) {
//                     animating = true;
//                     var target_slide = slides.eq(target);

//                     target_slide.fadeIn(transition);
//                     slides.not(target_slide).fadeOut(transition);

//                     slider.animate({ PaddingBottom: get_height(target) }, transition, function () {
//                         animating = false;
//                     });

//                     reset_timer();

//                 }
//             };

//             // Next Slide

//             function next_slide() {
//                 target = target === slide_count - 1 ? 0 : target + 1;
//                 animate_slide(target);
//             }

//             // Prev Slide

//             function prev_slide() {
//                 target = target === 0 ? slide_count - 1 : target - 1;
//                 animate_slide(target);
//             }

//             if (settings.arrows) {
//                 slider.append('<div class="sssprev"/>', '<div class="sssnext"/>');
//             }

//             next = slider.find('.sssnext'),
//             prev = slider.find('.sssprev');

//             $(window).load(function () {

//                 slider.css({ PaddingBottom: get_height(target) }).click(function (e) {
//                     clicked = $(e.target);
//                     if (clicked.is(next)) { next_slide() }
//                     else if (clicked.is(prev)) { prev_slide() }
//                 });

//                 animate_slide(target);

//                 $(document).keydown(function (e) {
//                     key = e.keyCode;
//                     if (key === 39) { next_slide() }
//                     else if (key === 37) { prev_slide() }
//                 });

//             });

//             // End

//         });
        

//     };
// })(jQuery, window, document);


export default class SSS {
    constructor(element, options) {
        let settings = jQuery.extend({
            slideShow: true,
            startOn: 0,
            speed: 3500,
            transition: 400,
            arrows: true,
        }, options);  
    
        this.wrapper = jQuery(element);
        this.slides = this.wrapper.children().wrapAll('<div class="sss"/>').addClass('ssslide');
        this.slider = this.wrapper.find('.sss');
        this.slide_count = this.slides.length;
        this.transition = settings.transition;
        this.starting_slide = settings.startOn;
        this.target = this.starting_slide > this.slide_count - 1 ? 0 : this.starting_slide;
        this.animating = false;
        this.clicked;
        this.timer;
        this.key;
        this.prev;
        this.next;

        // Reset Slideshow

        this.reset_timer = settings.slideShow ? function () {
            clearTimeout(this.timer);
            this.timer = setTimeout(next_slide, settings.speed);
        } : () => {};


        if (settings.arrows) {
            this.slider.append('<div class="sssprev"/>', '<div class="sssnext"/>');
        }

        this.next = this.slider.find('.sssnext'),
        this.prev = this.slider.find('.sssprev');


        this.prev[0].onclick = () => {
            this.prev_slide();
        }

        this.next[0].onclick = () => {
            this.next_slide();
        }

        this.animate_slide(this.target);

    }


    // Animate Slider

    get_height(target) {
        return ((this.slides.eq(target).height() / this.slider.width()) * 100) + '%';
    }

    animate_slide(target) {
        this.animating = true;
        let target_slide = this.slides.eq(target);
        target_slide.fadeIn(this.transition);
        this.slides.not(target_slide).fadeOut(this.transition);

        this.slider.animate({ PaddingBottom: this.get_height(target) }, this.transition, function () {
            this.animating = false;
        });

        this.reset_timer();
    };

    // Next Slide

    next_slide() {
        this.target = this.target === this.slide_count - 1 ? 0 : this.target + 1;
        this.animate_slide(this.target);
    }

    // Prev Slide

    prev_slide() {
        this.target = this.target - 1;
        if (this.target < 0) {
            this.target = this.slide_count - 1;
        }

        this.animate_slide(this.target);
    }

    go_to_slide(target) {
        this.target = target;
        this.animate_slide(target);
    }
}